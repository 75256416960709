import axios from "axios";

export const socialForm = () => ({
    isSuccessMessage: false,

  init() {
    document.addEventListener("DOMContentLoaded", () => {
      this.initFormSubmit();
    });
  },

  initFormSubmit() {
    document
      .getElementById("social-form")
      .addEventListener("submit", this.submitData.bind(this));
  },

  async submitData(e) {
    e.preventDefault();

    const requestURL = "https://script.google.com/macros/s/AKfycbzuTdM3SLLogNfnKOni7r0SyZ6a6xJCzrZQ2stKmYG_Jj8LhTthc9pWPnO4ktYetGZN/exec";
    const formData = new FormData(e.target);

    try {
      const response = await axios({
        method: "post",
        url: requestURL,
        data: formData,
      });
        this.isSuccessMessage = true;
    } catch (error) {
      console.error("ERROR:", error);
    } finally {
      e.target.reset();
    }

      setTimeout(() => {
          this.isSuccessMessage = false;
      }, 2000);
  },
});
